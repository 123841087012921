import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'

export default function useAdminsList(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'name', sortable: true },
    { key: 'instance', label: 'instance', sortable: true },
    { key: 'last_login', label: 'last login', sortable: true },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('usersPerPage'))
  const perPage = ref(defaultPerPage || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const filterDataStatus = ref('')
  const filterDataInstance = ref(null)
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  let debounceTimeout = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const clearFilters = () => {
    filterDataStatus.value = ''
    filterDataInstance.value = null
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch(filterDataInstance, () => refetchData())

  watch([currentPage, perPage, searchQuery, filterDataStatus],
    ([currentPageVal, perPageVal, searchQueryVal, filterDataStatusVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterDataStatusPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-users', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-users', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-users', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataStatusVal !== filterDataStatusPrevVal) {
        pushStateFiltersParams(root, 'admin-users', 'filterDataStatus', filterDataStatusVal)
      }
    })

  const fetchAdminsList = (ctx, callback) => {
    const query = { ...root.$route.query }
    const payloadData = {
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    if (query.searchQuery) payloadData.name = query.searchQuery
    if (query.filterDataStatus) payloadData.status = query.filterDataStatus
    if (filterDataInstance.value) payloadData.schoolId = filterDataInstance.value
    store
      .dispatch('app-admins/fetchAdminsList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta

        callback(data)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching admins' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const exportAdmins = queryParams => axios
    .get('/auth/users/export', { params: queryParams })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting admins',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchInstancesList = queryParams => axios
    .get('/auth/schools', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Instances list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'Active') return 'success'
    if (status === 'Inactive') return 'danger'
    return 'primary'
  }

  const actionOptions = [
    { value: 'edit', title: 'Edit', link: 'admin-profile' },
  ]

  return {
    fetchAdminsList,
    fetchInstancesList,
    exportAdmins,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    filterDataStatus,
    filterDataInstance,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,
    actionOptions,

    resolveStatusVariant,

    refetchData,
    clearFilters,
  }
}
